import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import styled from 'styled-components'

const StyledOptionWrapper = styled.div`
  text-align: center;
  padding: 100px 40px;
`;

const QRLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/scan-qr-code" {...props} />
))
const RestaurantsLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/restaurants" {...props} />
))

const IndexPage = () => (
  <Layout>
    <Container maxWidth={"lg"}>
      <SEO title="Home"/>
      <StyledOptionWrapper>
        <p>Znajdujesz się w restauracji i widzisz nasz QR Code?</p>
        <Button variant="contained" color="primary" size="large" component={QRLink}>
          Skanuj kod QR
        </Button>
      </StyledOptionWrapper>
      <StyledOptionWrapper>
        <p>Jesteś zainteresowany naszym projektem i chcesz dowiedzieć się gdzie możesz nas spotkać?</p>
        <Button variant="contained" color="primary" size="large" component={RestaurantsLink}>
          Zobacz restauracje
        </Button>
      </StyledOptionWrapper>
    </Container>
  </Layout>
)

export default IndexPage
